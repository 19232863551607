import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import './BuildWithUs.css';

import OnSiteBuilderLogo from '../icons/house.svg';
import ExpertLogo from '../icons/expertise.svg';
import HammerLogo from '../icons/hammer.svg';
import EmailLogo from '../icons/email.svg';
import WalkthroughLogo from '../icons/walkthrough.svg';

import { useEffect } from "react";
import { Link } from "react-router-dom";

const BuildWithUs = () => {
    useEffect(() => {
        document.body.style.overflowY = 'auto';
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="build-with-us-container">
            <Header />
            <div className="build-with-us-inner-container">
                <div className="build-with-us-hero-container">
                    <h1 className="build-with-us-hero-title">Our Expertise.<br /><span className="build-with-us-hero-title-highlight">Your Dream Home.</span></h1>
                    <p className="build-with-us-hero-statement">
                        We believe that building your dream home should be a seamless and enjoyable experience. Whether you're
                        looking to build a custom home or invest in new construction, we have the expertise and resources to
                        bring your vision to life.
                    </p>
                </div>
                <div className="builder-expertise-showcase-container">
                    <div className="builder-expertise-showcase-item">
                        <img
                            className="builder-expertise-showcase-icon"
                            src={OnSiteBuilderLogo}
                            alt="The builder is on-site daily and ensures all work is correctly completed."
                        />
                        <p className="builder-expertise-showcase-statement">On-Site Builder</p>
                    </div>
                    <div className="builder-expertise-showcase-item">
                        <img
                            className="builder-expertise-showcase-icon"
                            src={ExpertLogo}
                            alt="We are experts in our field and have 20 years of experience."
                        />
                        <p className="builder-expertise-showcase-statement">20 Years of Experience</p>
                    </div>
                    <div className="builder-expertise-showcase-item">
                        <img
                            className="builder-expertise-showcase-icon"
                            src={HammerLogo}
                            alt="We've built 1,000+ homes over the 20 years we've been in business."
                        />
                        <p className="builder-expertise-showcase-statement">1,000+ Homes Built</p>
                    </div>
                    <div className="builder-expertise-showcase-item">
                        <img
                            className="builder-expertise-showcase-icon"
                            src={EmailLogo}
                            alt="We communicate daily with every client."
                        />
                        <p className="builder-expertise-showcase-statement">Daily Communication</p>
                    </div>
                    <div className="builder-expertise-showcase-item">
                        <img
                            className="builder-expertise-showcase-icon"
                            src={WalkthroughLogo}
                            alt="We offer weekly walkthroughs during construction."
                        />
                        <p className="builder-expertise-showcase-statement">Weekly Walkthroughs</p>
                    </div>
                </div>
                <div className="more-information-container">
                    <p className="more-information-title">Interested?</p>
                    <p className="more-information-statement">
                        Contact us to learn more about our company and our process. We can meet and go over your
                        plans and ideas to help you get a better idea of what we'll build for you.
                    </p>
                    <Link to="/contact"><button className="more-information-btn">Learn More</button></Link>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default BuildWithUs;