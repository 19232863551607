// Styles
import './HomePlan.css';

// Components
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

import SquareFeetIcon from '../icons/ruler.svg';
import BedsIcon from '../icons/bedroom.svg';
import BathsIcon from '../icons/shower.svg';

/*
    PHOTOS
        ||   ||   ||
        ||   ||   ||
        ||   ||   ||
        \/   \/   \/
*/

// Azalea III
import AzaleaIIIExterior from '../images/azalea_iii_exterior.jpg';
import AzaleaIIILivingRoom1 from '../images/azalea_iii_living_room_1.png';
import AzaleaIIILivingRoom2 from '../images/azalea_iii_living_room_2.png';
import AzaleaIIILivingRoom3 from '../images/azalea_iii_living_room_2.png';
import AzaleaIIILivingRoom4 from '../images/azalea_iii_living_room_4.png';
import AzaleaIIILivingRoom5 from '../images/azalea_iii_living_room_5.png';
import AzaleaIIIKitchen1 from '../images/azalea_iii_kitchen_1.png';
import AzaleaIIIKitchen3 from '../images/azalea_iii_kitchen_3.png';
import AzaleaIIIKitchen4 from '../images/azalea_iii_kitchen_4.png';
import AzaleaIIIKitchen5 from '../images/azalea_iii_kitchen_5.png';
import AzaleaIIIPantry from '../images/azalea_iii_pantry_1.png';
import AzaleaIIIMasterBathroom from '../images/azalea_iii_master_bathroom_1.png';
import AzaleaIIIMasterBathroomShower from '../images/azalea_interior_master_shower.jpg';
import AzaleaIIIBasementLivingRoom1 from '../images/azalea_iii_basement_living_room_1.png';
import AzaleaIIIBasementLivingRoom2 from '../images/azalea_iii_basement_living_room_2.png';
import AzaleaIIIDeck from '../images/azalea_iii_deck.png';
import AzaleaIIIExteriorRear from '../images/azalea_iii_exterior_rear.png';

// Azalea II
import AzaleaExterior from '../images/azalea_ii_exterior.jpg';
import AzaleaExteriorRear from '../images/azalea_exterior_rear.jpg';
import AzaleaLivingRoom from '../images/azalea_interior_living_room.jpg';
import AzaleaLivingRoomKitchenDining from '../images/azalea_interior_living_room_kitchen.jpg';
import AzaleaKitchenDining from '../images/azalea_interior_kitchen_dining.jpg';
import AzaleaKitchen from '../images/azalea_interior_kitchen.jpg';
import AzaleaMasterBedroom from '../images/azalea_interior_master_bedroom.jpg';
import AzaleaMasterBathroom from '../images/azalea_interior_master_bathroom.jpg';
import AzaleaMasterBathroomShower from '../images/azalea_interior_master_shower.jpg';

// Azalea I
import AzaleaIExterior from '../images/azalea_i_exterior.jpg';
import AzaleaIEntry from '../images/Azalea I/entry.jpg';
import AzaleaILivingRoom1 from '../images/Azalea I/living_room_1.jpg';
import AzaleaILivingRoom2 from '../images/Azalea I/living_room_2.jpg';
import AzaleaILivingRoom3 from '../images/Azalea I/living_room_3.jpg';
import AzaleaILivingRoom4 from '../images/Azalea I/living_room_4.jpg';
import AzaleaIKitchen1 from '../images/Azalea I/kitchen_1.jpg';
import AzaleaIKitchen2 from '../images/Azalea I/kitchen_2.jpg';
import AzaleaIKitchen3 from '../images/Azalea I/kitchen_3.jpg';
import AzaleaIKitchen4 from '../images/Azalea I/kitchen_4.jpg';
import AzaleaIKitchen5 from '../images/Azalea I/kitchen_5.jpg';
import AzaleaIDining1 from '../images/Azalea I/dining_1.jpg';
import AzaleaIMasterBedroom1 from '../images/Azalea I/master_bedroom_1.jpg';
import AzaleaIMasterBathroom1 from '../images/Azalea I/master_bathroom_1.jpg';
import AzaleaIMasterBathroom2 from '../images/Azalea I/master_bathroom_2.jpg';
import AzaleaIBasementLivingRoom1 from '../images/Azalea I/basement_living_room_1.jpg';
import AzaleaIBasementLivingRoom2 from '../images/Azalea I/basement_living_room_2.jpg';
import AzaleaIBasementLivingRoom3 from '../images/Azalea I/basement_living_room_3.jpg';

// Poppe
import PoppeExterior from '../images/poppe_exterior.jpg';
import PoppeLivingRoomKitchenDining from '../images/poppe_living_room_kitchen_dining.jpg';
import PoppeLivingRoom1 from '../images/poppe_living_room_1.jpg';
import PoppeLivingRoom2 from '../images/poppe_living_room_2.jpg';
import PoppeLivingRoom3 from '../images/poppe_living_room_3.jpg';
import PoppeKitchenDining from '../images/poppe_kitchen_dining.jpg';
import PoppeKitchen from '../images/poppe_kitchen.jpg';
import PoppeDining from '../images/poppe_dining.jpg';
import PoppeMasterBedroom from '../images/poppe_master_bedroom.jpg';
import PoppeMasterBathroom from '../images/poppe_master_bathroom.jpg';

// Zumbul II
import ZumbulIIExterior1 from '../images/Zumbul II/exterior_front_1.jpg';
import ZumbulIIExterior2 from '../images/Zumbul II/exterior_front_2.jpg';
import ZumbulIILivingRoom1 from '../images/Zumbul II/living_room_1.jpg';
import ZumbulIILivingRoom2 from '../images/Zumbul II/living_room_2.jpg';
import ZumbulIILivingRoom3 from '../images/Zumbul II/living_room_3.jpg';
import ZumbulIILivingRoom4 from '../images/Zumbul II/living_room_4.jpg';
import ZumbulIIKitchen1 from '../images/Zumbul II/kitchen_1.jpg';
import ZumbulIIKitchen2 from '../images/Zumbul II/kitchen_2.jpg';
import ZumbulIIKitchen3 from '../images/Zumbul II/kitchen_3.jpg';
import ZumbulIIKitchen4 from '../images/Zumbul II/kitchen_4.jpg';
import ZumbulIIMasterBedroom1 from '../images/Zumbul II/master_bedroom_1.jpg';
import ZumbulIIMasterBathroom1 from '../images/Zumbul II/master_bathroom_1.jpg';
import ZumbulIIMasterBathroom2 from '../images/Zumbul II/master_bathroom_2.jpg';
import ZumbulIIBasementLivingRoom1 from '../images/Zumbul II/basement_living_room_1.jpg';
import ZumbulIIBasementLivingRoom2 from '../images/Zumbul II/basement_living_room_2.jpg';
import ZumbulIIBasementLivingRoom3 from '../images/Zumbul II/basement_living_room_3.jpg';
import ZumbulIIWetBar from '../images/Zumbul II/wet_bar_1.jpg';

// Bismarck
import BismarckExterior from '../images/Bismarck/exterior 1.jpg';
import BismarckEntry1 from '../images/Bismarck/entry.jpg';
import BismarckEntry2 from '../images/Bismarck/entry 2.jpg';
import BismarckLivingRoom1 from '../images/Bismarck/living room 1.jpg';
import BismarckLivingRoom2 from '../images/Bismarck/living room 2.jpg';
import BismarckLivingRoom3 from '../images/Bismarck/living room 3.jpg';
import BismarckLivingRoom4 from '../images/Bismarck/living room 4.jpg';
import BismarckLivingRoom5 from '../images/Bismarck/living room 5.jpg';
import BismarckKitchen1 from '../images/Bismarck/kitchen 1.jpg';
import BismarckKitchen2 from '../images/Bismarck/kitchen 2.jpg';
import BismarckKitchen3 from '../images/Bismarck/kitchen 3.jpg';
import BismarckKitchen4 from '../images/Bismarck/kitchen 4.jpg';
import BismarckKitchen5 from '../images/Bismarck/kitchen 5.jpg';
import BismarckKitchen6 from '../images/Bismarck/kitchen 6.jpg';
import BismarckDining1 from '../images/Bismarck/dining 1.jpg';
import BismarckMasterBedroom1 from '../images/Bismarck/master bedroom 1.jpg';
import BismarckMasterBedroom2 from '../images/Bismarck/master bedroom 2.jpg';
import BismarckMasterBathroom1 from '../images/Bismarck/master bathroom 1.jpg';
import BismarckMasterBathroom2 from '../images/Bismarck/master bathroom 2.jpg';
import BismarckMasterBathroom3 from '../images/Bismarck/master bathroom 3.jpg';
import BismarckBasementLivingRoom1 from '../images/Bismarck/basement living room 1.jpg';
import BismarckBasementLivingRoom2 from '../images/Bismarck/basement living room 2.jpg';

// Home Plan Source Data
import home_plan_information from '../frontend-data/home-plan-information';

// Core
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const HomePlan = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { homePlan } = useParams();

    const plan_information = home_plan_information.find(home_plan => home_plan.name === homePlan);
    const photos_to_use = [
        {
            plan: `Azalea III`,
            photos: [
                AzaleaIIIExterior, AzaleaIIILivingRoom1, AzaleaIIILivingRoom2,
                AzaleaIIILivingRoom3, AzaleaIIILivingRoom4, AzaleaIIILivingRoom5,
                AzaleaIIIKitchen1, AzaleaIIIKitchen3,
                AzaleaIIIKitchen4, AzaleaIIIKitchen5, AzaleaIIIPantry,
                AzaleaIIIMasterBathroom, AzaleaIIIMasterBathroomShower, AzaleaIIIBasementLivingRoom1,
                AzaleaIIIBasementLivingRoom2, AzaleaIIIDeck, AzaleaIIIExteriorRear
            ]
        },
        {
            plan: `Azalea II`,
            photos: [
                AzaleaExterior, AzaleaExteriorRear, AzaleaLivingRoom, 
                AzaleaLivingRoomKitchenDining, AzaleaKitchenDining, 
                AzaleaKitchen, AzaleaMasterBedroom, AzaleaMasterBathroom, 
                AzaleaMasterBathroomShower
            ]
        },
        {
            plan: `Poppe`,
            photos: [
                PoppeExterior, PoppeLivingRoomKitchenDining, PoppeLivingRoom1,
                PoppeLivingRoom2, PoppeLivingRoom3, PoppeKitchenDining,
                PoppeKitchen, PoppeDining, PoppeMasterBedroom,
                PoppeMasterBathroom
            ]
        },
        {
            plan: `Azalea I`,
            photos: [
                AzaleaIExterior, AzaleaIEntry, AzaleaILivingRoom1,
                AzaleaILivingRoom2, AzaleaILivingRoom2, AzaleaILivingRoom3,
                AzaleaILivingRoom4, AzaleaIKitchen1, AzaleaIKitchen2,
                AzaleaIKitchen3, AzaleaIKitchen4, AzaleaIKitchen5,
                AzaleaIDining1, AzaleaIMasterBedroom1, AzaleaIMasterBedroom1,
                AzaleaIMasterBathroom1, AzaleaIMasterBathroom2, AzaleaIBasementLivingRoom1,
                AzaleaIBasementLivingRoom2, AzaleaIBasementLivingRoom3

            ]
        },
        {
            plan: `Lilly II`,
            photos: [
                ZumbulIIExterior1, ZumbulIIExterior2, ZumbulIILivingRoom1,
                ZumbulIILivingRoom2, ZumbulIILivingRoom3, ZumbulIILivingRoom4,
                ZumbulIIKitchen1, ZumbulIIKitchen2, ZumbulIIKitchen3,
                ZumbulIIKitchen4, ZumbulIIMasterBedroom1, ZumbulIIMasterBathroom1,
                ZumbulIIMasterBathroom2, ZumbulIIBasementLivingRoom1, ZumbulIIBasementLivingRoom2,
                ZumbulIIBasementLivingRoom3, ZumbulIIWetBar
            ]
        },
        {
            plan: `Bismarck`,
            photos: [
                BismarckExterior, BismarckEntry1, BismarckEntry2,
                BismarckLivingRoom1, BismarckLivingRoom2, BismarckLivingRoom3,
                BismarckLivingRoom4, BismarckLivingRoom5, BismarckKitchen1,
                BismarckKitchen2, BismarckKitchen3, BismarckKitchen4,
                BismarckKitchen5, BismarckKitchen6, BismarckDining1,
                BismarckMasterBedroom1, BismarckMasterBedroom2, BismarckMasterBathroom1,
                BismarckMasterBathroom2, BismarckMasterBathroom3, BismarckBasementLivingRoom1,
                BismarckBasementLivingRoom2
            ]
        }
    ];

    return (
        <div className='home-plan-container'>
            <Header />
            <div className='home-plan-hero-container'>
                <h1 className='home-plan-hero-title'>{plan_information.name}</h1>
            </div>
            <div className='home-plan-main-features-container'>
                <div className='home-plan-main-features-feature'>
                    <img
                        className='home-plan-main-features-feature-icon'
                        src={SquareFeetIcon}
                        alt='Square Feet Icon'
                    />
                    <p className='home-plan-main-features-feature-statement'>{plan_information.square_feet}</p>
                </div>
                <div className='home-plan-main-features-feature'>
                    <img
                        className='home-plan-main-features-feature-icon'
                        src={BedsIcon}
                        alt='Square Feet Icon'
                    />
                    <p className='home-plan-main-features-feature-statement'>{plan_information.beds}</p>
                </div>
                <div className='home-plan-main-features-feature'>
                    <img
                        className='home-plan-main-features-feature-icon'
                        src={BathsIcon}
                        alt='Square Feet Icon'
                    />
                    <p className='home-plan-main-features-feature-statement'>{plan_information.baths}</p>
                </div>
            </div>
            <div className='home-plan-extra-features-container'>
                <p className='home-plan-extra-features-title'>Additional Features</p>
                <ul className='home-plan-extra-features-list'>
                    {
                        plan_information.features.map((feature, index) => <li key={index} className='home-plan-extra-features-list-item'>{feature}</li>)
                    }
                </ul>
            </div>
            <div className='home-plan-photos-container'>
                <ul className='home-plan-photos-list'>
                    {
                        photos_to_use.find(plan => plan.plan === homePlan).photos.map((photo, index) => <img key={index} className='home-plan-photo' src={photo} alt='Home Plan Photo' />)
                    }
                </ul>
            </div>
            <Footer />
        </div>
    );
};

export default HomePlan;
