import './FeaturesCard.css';

import House from '../../icons/house.svg';
import Clock from '../../icons/clock.svg';
import Email from '../../icons/email.svg';

const FeaturesCard = (props) => {
    const getFeaturesCardIcon = (iconToGet) => {
        switch(iconToGet) {
            case 'House':
                return House;
            case 'Clock':
                return Clock;
            case 'Email':
                return Email;
        };
    };

    return (
        <div className='features-card-container'>
            <img
                className='features-card-icon'
                src={getFeaturesCardIcon(props.featuresCardIcon)}
                alt='Features Card Icon'
            />
            <p className='features-card-title'>{props.title}</p>
            <p className='features-card-statement'>{props.statement}</p>
        </div>
    );
};

export default FeaturesCard;
