// STYLES
import './Index.css';

// COMPONENTS
import Header from '../components/Header/Header.jsx';
import Footer from '../components/Footer/Footer.jsx';
import HouseCard from '../components/HouseCard/HouseCard.jsx';
import FeaturesCard from '../components/FeaturesCard/FeaturesCard.jsx';

// IMAGES
import HomeImage from '../images/azalea_i_exterior.jpg';

// IMAGES FOR 'Guaranteed Quality' SECTION
import Azalea_I from '../images/azalea_i_exterior.jpg';
import Azalea_II from '../images/azalea_ii_exterior.jpg';
import Azalea_III from '../images/azalea_iii_exterior.jpg';
import Lilly_II from '../images/722_exterior.jpg';
// import Zumbul_II from '../images/zumbul_ii_exterior.jpg';
import Bismark from '../images/Bismarck/exterior 1.jpg';
import Poppe from '../images/poppe_exterior.jpg';

// CORE
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Index = () => {
    const guaranteedQualityImages = useState([
        Azalea_I, Azalea_II, Azalea_III,
        Lilly_II, Bismark, Poppe
    ]);
    // const selectRandomImageForGuaranteedQualitySection = () => guaranteedQualityImages[Math.floor(Math.random() * guaranteedQualityImages.length) + 1];
    // const guaranteedQualityImage = useState(selectRandomImageForGuaranteedQualitySection());

    return (
        <div>
            <Header />
            <div className='landing-page-hero-container'>
                <div className='landing-page-hero-welcome-container'>
                <h1 className='landing-page-hero-title'>Let Us<br />Build <span className='landing-page-hero-title-highlight'>Your<br />Dream Home</span></h1>
                <p className='landing-page-hero-statement'>
                    We're a premier Iowa builder responsible for building dream homes throughout Central Iowa since 2005.
                </p>
                <Link to="/build-with-us"><button className='landing-page-build-with-us-btn'>Build With Us</button></Link>
                </div>
                <div className='house-cards-container'>
                    <HouseCard homePlan='Azalea III' price='$400,000' square_feet='1,522' beds='4' baths='3' />
                    <HouseCard homePlan='Azalea II' price='$445,000' square_feet='1,514' beds='5' baths='3' />
                    <HouseCard homePlan='Poppe' price='$475,000' square_feet='1,679' beds='5' baths='3' />
                    <HouseCard homePlan='Azalea I' price='$445,500' square_feet='1,541' beds='5' baths='3' />
                    <HouseCard homePlan='Lilly II' price='$445,000' square_feet='1,512' beds='4' baths='3' />
                    <HouseCard homePlan='Bismarck' price='$475,000' square_feet='1,653' beds='5' baths='3' />
                    {/* <HouseCard homePlan='Marigold' price='$475,000' square_feet='1,550' beds='5' baths='3' /> */}
                    {/* <HouseCard homePlan='Lilly' price='$445,000' square_feet='1,550' beds='5' baths='3' /> */}
                    {/* <HouseCard homePlan='Zumbul I' price='$341,000' square_feet='1,550' beds='5' baths='3' /> */}
                </div>
            </div>
            <div className='features-cards-container'>
                <FeaturesCard 
                featuresCardIcon='House'
                title="On-Site Builder"
                statement='Our builder is on-site daily to ensure all work is completed correctly and on-time.'
                />
                <FeaturesCard
                featuresCardIcon='Clock'
                title="Fast Build Time"
                statement="Over the 20 years we've been in business, we perfected our trade and are able to build your home in 4-5 months."
                />
                <FeaturesCard 
                featuresCardIcon='Email'
                title="Daily Communication"
                statement="We communicate daily with our clients so you'll always be in the loop and know what's going on with your new home."
                />
            </div>
            <div className='free-consultation-container'>
                <p className='free-consultation-title'>Free Consultation</p>
                <p className='free-consultation-statement'>
                We offer a free builder-client consultation to all prospective clients. During this time we go over
                what you're looking for in your new home and make a plan to ensure your home is everything you want
                it to be!
                </p>
                <Link to="/contact"><button className='free-consultation-btn'>Schedule Free Consultation</button></Link>
            </div>
            <div className='builder-showcase-container'>
                <div className='builder-showcase-container-left'>
                    <p className='builder-showcase-container-left-statement'>
                        For almost 20 years, our homes have been a staple of integrity and quality construction throughout Central Iowa. We pride ourselves in setting the standard for quality new home construction.
                    </p>
                </div>
                <div className='builder-showcase-container-right'>
                    <div className='builder-showcase-container-right-item'>
                        <p className='builder-showcase-container-right-item-title'>20</p>
                        <p className='builder-showcase-container-right-item-statement'>Years of Experience</p>
                    </div>
                    <div className='builder-showcase-container-right-item'>
                        <p className='builder-showcase-container-right-item-title'>1,000+</p>
                        <p className='builder-showcase-container-right-item-statement'>Homes Built</p>
                    </div>
                    <div className='builder-showcase-container-right-item'>
                        <p className='builder-showcase-container-right-item-title'>100%</p>
                        <p className='builder-showcase-container-right-item-statement'>Client Satisfaction</p>          
                    </div>
                </div>
            </div>
            <div className='builder-guarantee-container'>
                <img
                className='builder-guarantee-image'
                src={guaranteedQualityImages[0][Math.floor(Math.random() * guaranteedQualityImages[0].length)]}
                alt='Image of Home Constructed by DS Solid Construction'
                />
                <div className='builder-guarantee-message-container'>
                <p className='builder-guarentee-message-title'>Guaranteed Quality</p>
                <p className='builder-guarantee-message-statement'>
                    We understand building a new home is stressful. Our expertise provides an easy way to ensure your new
                    home is built properly and without delays in construction. Over the 20 years we've been in business, we've
                    perfected our trade and can guarantee a quality built home.
                </p>
                <p className='builder-guarantee-message-statement'>
                    Along the way, you'll receive daily updates regarding the build process and you'll have a direct line
                    of communication with our listing agent. Whenever you have questions, you're encouraged to reach out
                    for clarification.
                </p>
                </div>
            </div>
            <div className='get-started-today-container'>
                <div className='get-started-today-inner-container'>
                <p className='get-started-today-title'>Get Started Today</p>
                <p className='get-started-today-statement'>
                    Contact us today and we'll be sure to get back to you with more information about building your new home. We respond quickly
                    and are able to clear our schedule in order to meet with you and go over any plans and ideas you may have.
                </p>
                <Link to="/contact"><button className='get-started-today-btn'>Get Started Today</button></Link>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Index;