import './Header.css';

import CompanyLogo from '../../images/company_logo.png';
import Hamburger from '../../icons/hamburger.svg';
import Exit from '../../icons/exit.svg';

import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    // useEffect(() => {
    //     const navItems = document.getElementsByClassName("nav-item");
    //     Array.from(navItems).forEach(navItem => {
    //         navItem.addEventListener("click", () => {
    //             console.log("clicked nav-item");
    //         });
    //     });
    // }, []);

    const hamburgerIcon = useRef(null);
    const mobileMenu = useRef(null);

    const openMobileMenu = () => {
        mobileMenu.current.style.display = 'flex';
        document.body.style.overflowY = 'hidden';
    };

    const closeMobileMenu = () => {
        mobileMenu.current.style.display = 'none';
        document.body.style.overflowY = 'auto';
    };

    return (
        <header className='header-container'>
            <Link to='/'>
                <img
                    className='header-logo-placeholder'
                    src={CompanyLogo}
                    alt='Company Logo Placeholder'
                />
            </Link>
            <nav className='header-nav-items'>
                <Link to='/available-homes' className='nav-item'>Available Homes</Link>
                <Link to='/build-with-us' className='nav-item'>Build With Us</Link>
                {/* <Link to='/home-plans' className='nav-item'>Home Plans</Link> */}
                <Link to='/contact' className='nav-item'>Contact</Link>
            </nav>
            <img
                className='nav-hamburger-icon'
                src={Hamburger}
                alt='Navigation Hamburger Icon'
                ref={hamburgerIcon}
                onClick={openMobileMenu}
            />
            <div className='mobile-menu-container' ref={mobileMenu}>
                <img
                    className='mobile-menu-exit-icon'
                    src={Exit}
                    alt='Mobile Menu Exit Icon'
                    onClick={closeMobileMenu}
                />
                <nav className='mobile-menu-nav-container'>
                    <Link to='/available-homes' className='mobile-menu-nav-item'>Available Homes</Link>
                    <Link to='/build-with-us' className='mobile-menu-nav-item'>Build With Us</Link>
                    {/* <Link to='/home-plans' className='nav-item'>Home Plans</Link> */}
                    <Link to='/contact' className='mobile-menu-nav-item'>Contact</Link>
                </nav>
            </div>
        </header>
    );
};

export default Header;
