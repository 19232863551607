// Styles
import './AvailableHomes.css';

// Components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import AvailableHomeCard from '../components/AvailableHomeCard/AvailableHomeCard';

// Available Homes Source Data
import AvailableHomesData from '../frontend-data/available-homes.js';

// Core
import { useEffect } from 'react';

const AvailableHomes = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <div className="available-homes-container">
                {
                    AvailableHomesData.available_homes.map(available_home => {
                        return (
                            <AvailableHomeCard
                                price={available_home.price}
                                state={available_home.state}
                                beds={available_home.beds}
                                baths={available_home.baths}
                                squareFeet={available_home.square_feet}
                                lotSize={available_home.lot_size}
                                link={available_home.link}
                                address={available_home.address}
                                key={`${available_home.address.house_number} ${available_home.address.street_name}`}
                            />
                        );
                    })
                }
            </div>
            <Footer />
        </>
    );
};

export default AvailableHomes;