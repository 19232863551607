import './HouseCard.css';

import AzaleaIIIImage from '../../images/azalea_iii_exterior.jpg';
import AzaleaIIImage from '../../images/azalea_ii_exterior.jpg';
import PoppeImage from '../../images/poppe_exterior.jpg';
import AzaleaIImage from '../../images/azalea_i_exterior.jpg';
import LillyIIImage from '../../images/zumbul_ii_exterior.jpg';
import BismarckImage from '../../images/Bismarck/exterior 1.jpg';

import SquareFeetIcon from '../../icons/ruler.svg';
import BedroomIcon from '../../icons/bedroom.svg';
import BathroomIcon from '../../icons/shower.svg';

import { Link } from 'react-router-dom';

const HouseCard = (props) => {
    const getHomePlanImage = (home_plan) => {
        switch(home_plan) {
            case 'Azalea III':
                return AzaleaIIIImage;
            case 'Azalea II':
                return AzaleaIIImage;
            case 'Poppe':
                return PoppeImage;
            case 'Lilly II':
                return LillyIIImage;
            case 'Azalea I':
                return AzaleaIImage;
            case 'Bismarck':
                return BismarckImage;
        };
    };

    return (
        <div className='house-card-container'>
            <img
                className='house-card-image-placeholder'
                src={getHomePlanImage(props.homePlan)}
                alt='HouseCard Image Placeholder'
            />
            <div className='house-card-info-container'>
            <div className='house-card-plan-price-container'>
                <p className='house-card-plan'>{props.homePlan}</p>
                {/* <p className='house-card-price'>{props.price}</p> */}
            </div>
            <div className='house-card-square-feet-bed-bath-container'>
                <div className='house-card-square-feet-bed-bath-item'>
                    <img 
                        className='house-card-square-feet-bed-bath-item-logo'
                        src={SquareFeetIcon}
                        alt='Ruler'
                    />
                    <p className='house-card-square-feet-bed-bath-item-statement'>{props.square_feet} ft.<sup>2</sup></p>
                </div>
                <div className='house-card-square-feet-bed-bath-item'>
                    <img 
                        className='house-card-square-feet-bed-bath-item-logo'
                        src={BedroomIcon}
                        alt='Ruler'
                    />
                    <p className='house-card-square-feet-bed-bath-item-statement'>{props.beds} Beds</p>
                </div>
                <div className='house-card-square-feet-bed-bath-item'>
                    <img 
                        className='house-card-square-feet-bed-bath-item-logo'
                        src={BathroomIcon}
                        alt='Ruler'
                    />
                    <p className='house-card-square-feet-bed-bath-item-statement'>{props.baths} Baths</p>
                </div>
            </div>
            <Link to={`/home-plans/${props.homePlan}`}>
                <button className='house-card-view-more-btn'>View Plan</button>
            </Link>
            </div>
        </div>
    );
};

export default HouseCard;
