import './App.css';

import HomePage from './frontend/pages/Index';
import AvailableHomesPage from './frontend/pages/AvailableHomes';
import BuildWithUs from './frontend/pages/BuildWithUs';
import HomePlans from './frontend/pages/HomePlans';
import Contact from './frontend/pages/Contact';
import HomePlan from './frontend/pages/HomePlan';

import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

const App = () => {
  useEffect(() => {
    document.body.style.overflowY = 'auto';
  }, []);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path='/available-homes' element={<AvailableHomesPage />} />
      <Route path='/build-with-us' element={<BuildWithUs />} />
      {/* <Route path='/home-plans' element={<HomePlans />} /> */}
      <Route path='/contact' element={<Contact />} />
      <Route path='/home-plans/:homePlan' element={<HomePlan />} />
    </Routes>
  );
};

export default App;
