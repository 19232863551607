import './AvailableHomeCard.css';

import ForestRidgeCourt_1121 from '../../images/azalea_ii_exterior.jpg';

import SquareFeetIcon from '../../icons/ruler.svg';
import LotSizeIcon from '../../icons/lot_size.svg';
import BedsIcon from '../../icons/bedroom.svg';
import BathsIcon from '../../icons/shower.svg';

import _1117_ from '../../images/1117.jpg';
import _1120_ from '../../images/1120.jpg';
import _1116_ from '../../images/1116.jpg';
import _1108_ from '../../images/1108.jpg';
import _1121_ from '../../images/1121.jpg';
import _1113_ from '../../images/1113.jpg';
import _1109_ from '../../images/zumbul_ii_exterior.jpg';
import _890_ from '../../images/890 Daybreak Drive.jpg';
import _2815_ from '../../images/2815.jpg';
import _722_ from '../../images/722_exterior.jpg';
import _726_ from '../../images/_726_.jpg';
import _720_ from '../../images/_720_.jpg';
import _724_ from '../../images/_724_.jpg';
import _725_ from '../../images/_725_.jpeg';
import _728_ from '../../images/_728_.jpeg';

import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const AvailableHomeCard = (props) => {
    useEffect(() => {
        document.body.style.overflowY = 'auto';
    }, []);

    const getAvailableCardHomeImage = (address) => {
        switch(address) {
            case `1120 NE Forest Ridge Court`:
                return _1120_;
            case `1117 NE Forest Ridge Court`:
                return _1117_;
            case `1116 NE Forest Ridge Court`:
                return _1116_;
            case `1108 NE Forest Ridge Court`:
                return _1108_;
            case `1121 NE Forest Ridge Court`:
                return _1121_;
            case `1113 NE Forest Ridge Court`:
                return _1113_;
            case `1109 NE Forest Ridge Court`:
                return _1109_;
            case `890 Daybreak Drive`:
                return _890_;
            case `2815 Serenity Drive`:
                return _2815_;
            case '722 NE Country Meadow Drive':
                return _722_;
            case '726 NE Country Meadow Drive':
                return _726_;
            case '720 NE Doe Pointe Drive':
                return _720_;
            case '724 NE Doe Pointe Drive':
                return _724_;
            case '725 NE Country Meadow Drive':
                return _725_;
            case '728 NE Doe Pointe Drive':
                return _728_;
        };
    };

    return (
        <div className='available-home-card-container'>
            <img
                className='available-home-card-image'
                src={getAvailableCardHomeImage(`${props.address.house_number} ${props.address.street_name}`)}
                alt='Image of Home'
            />
            <p className='available-home-card-address'>{`${props.address.house_number} ${props.address.street_name}, ${props.address.city}`}</p>
            <div className='available-home-card-price-state-container'>
                <p className='available-home-card-price-state-container-price'>{props.price}</p>
                <p className='available-home-card-price-state-container-state'>{props.state}</p>
            </div>
            <ul className='available-home-card-features-container-list'>
                <li className='available-home-card-features-container-list-item'>
                    <img
                        className='available-home-card-features-container-list-item-icon'
                        src={SquareFeetIcon}
                        alt='Square Feet'
                    />
                    <p className='available-home-card-features-container-list-item-icon-data'>
                        {props.squareFeet} ft.<sup>2</sup>
                    </p>
                </li>
                <li className='available-home-card-features-container-list-item'>
                    <img
                        className='available-home-card-features-container-list-item-icon'
                        src={BedsIcon}
                        alt='Number of Bedrooms'
                    />
                    <p className='available-home-card-features-container-list-item-icon-data'>
                        {props.beds}
                    </p>
                </li>
                <li className='available-home-card-features-container-list-item'>
                    <img
                        className='available-home-card-features-container-list-item-icon'
                        src={BathsIcon}
                        alt='Number of Bathrooms'
                    />
                    <p className='available-home-card-features-container-list-item-icon-data'>
                        {props.baths}
                    </p>
                </li>
            </ul>
            <Link to={props.link}>
                <button className='available-home-card-btn'>View Home</button>
            </Link>
        </div>
    );
};

export default AvailableHomeCard;
