const home_plan_information = [
    {
        name: `Azalea III`,
        square_feet: `2,708`,
        beds: 4,
        baths: 3,
        features: [
            `Covered Deck`,
            `3 Car Garage`,
            `Main Level Fireplace`,
            `LVP Flooring`
        ]
    },
    {
        name: `Azalea II`,
        square_feet: `2,800`,
        beds: 5,
        baths: 3,
        features: [
            `Covered Deck`,
            `3 Car Garage`,
            `Main Level Fireplace`,
            `Basement Fireplace`,
            `LVP Flooring`
        ]
    },
    {
        name: `Azalea I`,
        square_feet: `2,750`,
        beds: 5,
        baths: 3,
        features: [
            `Covered Deck`,
            `3 Car Garage`,
            `Main Level Fireplace`,
            `Basement Fireplace`,
            `LVP Flooring`
        ]
    },
    {
        name: `Lilly II`,
        square_feet: `3,000`,
        beds: 5,
        baths: 3,
        features: [
            `Covered Deck`,
            `3 Car Garage`,
            `Main Level Fireplace`,
            `Basement Fireplace`,
            `LVP Flooring`
        ]
    },
    {
        name: `Poppe`,
        square_feet: `2,900`,
        beds: 5,
        baths: 3,
        features: [
            `Covered Deck`,
            `3 Car Garage`,
            `Main Level Fireplace`,
            `Basement Fireplace`,
            `LVP Flooring`
        ]
    },
    {
        name: `Lilly II`,
        square_feet: `2,900`,
        beds: 5,
        baths: 3,
        features: [
            `Covered Deck`,
            `3 Car Garage`,
            `Main Level Fireplace`,
            `Basement Fireplace`,
            `LVP Flooring`
        ]
    },
    {
        name: `Bismarck`,
        square_feet: `2,853`,
        beds: 5,
        baths: 3,
        features: [
            `Covered Deck`,
            `3 Car Garage`,
            `Main Level Fireplace`,
            `Basement Fireplace`,
            `LVP Flooring`
        ]
    }
];

export default home_plan_information;
