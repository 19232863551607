import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import './Contact.css';

import Phone from '../icons/phone.svg';
import Email from '../icons/email.svg';

import { useEffect } from "react";

const Contact = () => {
    useEffect(() => {
        document.body.style.overflowY = 'auto'
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="contact-container">
            <Header />
            <div className="contact-landing-hero-container">
                <h1 className="contact-landing-hero-title">Contact Us</h1>
                <p className="contact-landing-hero-statement">
                    Contact us with any questions you may have. We offer a quick response time and are sure to
                    answer any questions you may have.
                </p>
            </div>
            <div className="contact-options-container">
                <div className="contact-options-option-container">
                    <img
                        className="contact-options-option-icon"
                        src={Phone}
                        alt="Phone Contact Option Logo"
                    />
                    <p className="contact-options-option-statement">515-803-4705</p>
                </div>
                <div className="contact-options-option-container">
                    <img
                        className="contact-options-option-icon"
                        src={Email}
                        alt="Email Contact Option Logo"
                    />
                    <p className="contact-options-option-statement">DSSolidConstruction@gmail.com</p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Contact;