const available_homes = {
  available_homes: [
    {
      address: {
        house_number: `726`,
        street_name: `NE Country Meadow Drive`,
        city: `Ankeny`,
        state: `Iowa`,
        zip: `50021`,
      },
      plan: `Azalea`,
      square_feet: `2,772`,
      beds: 5,
      baths: 3,
      lot_size: `9,047`,
      price: `$479,900`,
      state: `For Sale`,
      link: `https://www.zillow.com/homedetails/726-NE-Country-Meadow-Dr-Ankeny-IA-50021/2053964095_zpid/?view=public`,
    },
    {
      address: {
        house_number: `720`,
        street_name: `NE Doe Pointe Drive`,
        city: `Ankeny`,
        state: `Iowa`,
        zip: `50021`,
      },
      plan: `Azalea II`,
      square_feet: `2,617`,
      beds: 5,
      baths: 3,
      lot_size: `9,713`,
      price: `$479,900`,
      state: `For Sale`,
      link: `https://www.zillow.com/homedetails/720-NE-Doe-Point-Dr-Ankeny-IA-50021/2055201314_zpid/?view=public`,
    },
    {
      address: {
        house_number: `724`,
        street_name: `NE Doe Pointe Drive`,
        city: `Ankeny`,
        state: `Iowa`,
        zip: `50021`,
      },
      plan: `Azalea III`,
      square_feet: `2,750`,
      beds: 4,
      baths: 3,
      lot_size: `8,276`,
      price: `$479,900`,
      state: `For Sale`,
      link: `https://www.zillow.com/homedetails/724-NE-Doe-Pointe-Dr-Ankeny-IA-50021/2054483363_zpid/?view=public`,
    },
    {
      address: {
        house_number: `728`,
        street_name: `NE Doe Pointe Drive`,
        city: `Ankeny`,
        state: `Iowa`,
        zip: `50021`,
      },
      plan: `Lilly II`,
      square_feet: `2,836`,
      beds: 4,
      baths: 3,
      lot_size: `7,008`,
      price: `$479,900`,
      state: `For Sale`,
      link: `https://www.zillow.com/homes/728-NE-Doe-Pointe-Dr-Ankeny,-IA-50021_rb/2054550521_zpid/?`,
    },
    {
      address: {
        house_number: `725`,
        street_name: `NE Country Meadow Drive`,
        city: `Ankeny`,
        state: `Iowa`,
        zip: `50021`,
      },
      plan: `Lilly II`,
      square_feet: `2,836`,
      beds: 4,
      baths: 3,
      lot_size: `8,189`,
      price: `$479,900`,
      state: `For Sale`,
      link: `https://www.zillow.com/homedetails/725-NE-Country-Meadow-Dr-Ankeny-IA-50021/2053964092_zpid/?view=public`,
    },
    {
      address: {
        house_number: `1121`,
        street_name: `NE Forest Ridge Court`,
        city: `Ankeny`,
        state: `Iowa`,
        zip: `50021`,
      },
      plan: `Azalea`,
      square_feet: `2,741`,
      beds: 5,
      baths: 3,
      lot_size: `11,000`,
      price: `$529,000`,
      state: `SOLD`,
      link: `https://www.zillow.com/homes/1121-ne-forest-ridge-court-ankeny-iowa_rb/2060783851_zpid/`,
    },
    {
      address: {
        house_number: `722`,
        street_name: `NE Country Meadow Drive`,
        city: `Ankeny`,
        state: `Iowa`,
        zip: `50021`,
      },
      plan: `Azalea III`,
      square_feet: `2,708`,
      beds: 4,
      baths: 3,
      lot_size: `8,799`,
      price: `$479,000`,
      state: `SOLD`,
      link: `https://www.zillow.com/homedetails/722-NE-Meadow-Ln-Ankeny-IA-50021/2056505223_zpid/`,
    },
    {
      address: {
        house_number: `1117`,
        street_name: `NE Forest Ridge Court`,
        city: `Ankeny`,
        state: `Iowa`,
        zip: `50021`,
      },
      plan: `Azalea`,
      square_feet: `2,800`,
      beds: 5,
      baths: 3,
      lot_size: `11,000`,
      price: `$532,900`,
      state: `SOLD`,
      link: `https://www.zillow.com/homes/1117-ne-forest-ridge-court-ankeny_rb/2061260834_zpid/`,
    },
    {
      address: {
        house_number: `2815`,
        street_name: `Serenity Drive`,
        city: `Waukee`,
        state: `Iowa`,
        zip: `50263`,
      },
      plan: `Lilly II`,
      square_feet: `2,862`,
      beds: 4,
      baths: 3,
      lot_size: `10,867`,
      price: `$564,000`,
      state: `SOLD`,
      link: `https://www.zillow.com/homes/2815-Serenity-Dr-Waukee,-IA-50263_rb/337921364_zpid/`,
    },
    {
      address: {
        house_number: `1116`,
        street_name: `NE Forest Ridge Court`,
        city: `Ankeny`,
        state: `Iowa`,
        zip: `50021`,
      },
      plan: `Poppe`,
      square_feet: `2,910`,
      beds: 5,
      baths: 3,
      lot_size: `10,000`,
      price: `$559,900`,
      state: `SOLD`,
      link: `https://www.zillow.com/homes/1116-ne-forest-ridge-court-ankeny-iowa_rb/2061261539_zpid/`,
    },
    {
      address: {
        house_number: `1108`,
        street_name: `NE Forest Ridge Court`,
        city: `Ankeny`,
        state: `Iowa`,
        zip: `50021`,
      },
      plan: `Marigold`,
      square_feet: `3,042`,
      beds: 5,
      baths: 3,
      lot_size: `10,000`,
      price: `$569,900`,
      state: `SOLD`,
      link: `https://www.zillow.com/homes/1108-ne-forest-ridge-court-ankeny-iowa_rb/2061203849_zpid/`,
    },
    {
      address: {
        house_number: `1120`,
        street_name: `NE Forest Ridge Court`,
        city: `Ankeny`,
        state: `Iowa`,
        zip: `50021`,
      },
      plan: `Bismarck`,
      square_feet: `2,853`,
      beds: 5,
      baths: 3,
      lot_size: `10,500`,
      price: `$549,900`,
      state: `SOLD`,
      link: `https://www.zillow.com/homes/1120-ne-forest-ridge-court-ankeny_rb/2061204008_zpid/`,
    },
    {
      address: {
        house_number: `1113`,
        street_name: `NE Forest Ridge Court`,
        city: `Ankeny`,
        state: `Iowa`,
        zip: `50021`,
      },
      plan: `Azalea`,
      square_feet: `2,832`,
      beds: 5,
      baths: 3,
      lot_size: `13,000`,
      price: `$539,900`,
      state: `SOLD`,
      link: `https://www.zillow.com/homes/1113-ne-forest-ridge-court-ankeny-iowa_rb/2061204686_zpid/`,
    },
    {
      address: {
        house_number: `1109`,
        street_name: `NE Forest Ridge Court`,
        city: `Ankeny`,
        state: `Iowa`,
        zip: `50021`,
      },
      plan: `Lilly II`,
      square_feet: `2,862`,
      beds: 4,
      baths: 3,
      lot_size: `28,192`,
      price: `$580,000`,
      state: `SOLD`,
      link: `https://www.zillow.com/homes/1109-ne-forest-ridge-court-ankeny_rb/2061261454_zpid/?`,
    },
    {
      address: {
        house_number: `890`,
        street_name: `Daybreak Drive`,
        city: `Waukee`,
        state: `Iowa`,
        zip: `50263`,
      },
      plan: `Azalea II`,
      square_feet: `2,730`,
      beds: 4,
      baths: 3,
      lot_size: `13,503`,
      price: `$545,300`,
      state: `SOLD`,
      link: `https://www.zillow.com/homes/890-daybreak-drive-waukee-iowa_rb/337921358_zpid/`,
    },
  ],
};

export default available_homes;
