// Styles
import './Footer.css';

// Icons
import FacebookLogo from '../../icons/facebook.svg';
import InstagramLogo from '../../icons/instagram.svg';

// Core
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className='footer-container'>
            <div className='footer-section-container'>
                <p className='footer-section-title'>Company</p>
                <ul className='footer-section-list'>
                    <Link to="/available-homes"><li className='footer-section-list-item'>Available Homes</li></Link>
                    <Link to="/build-with-us"><li className='footer-section-list-item'>Build With Us</li></Link>
                    <Link to="/contact"><li className='footer-section-list-item'>Schedule Free Consultation</li></Link>
                    <Link to="/contact"><li className='footer-section-list-item'>Contact</li></Link>
                </ul>
            </div>
            <div className='footer-section-container'>
                <p className='footer-section-title'>Social Media</p>
                <ul className='footer-section-list footer-social-media-list'>
                    <a href='https://www.facebook.com/DSSolidConstruction'>
                        <li className='footer-section-list-item'>
                            <img
                                className='footer-section-social-media-icon'
                                src={FacebookLogo}
                                alt="Facebook - Social Media"
                            />
                        </li>
                    </a>
                    <a href='https://www.instagram.com/ds_solid_construction'>
                        <li className='footer-section-list-item'>
                            <img
                                className='footer-section-social-media-icon'
                                src={InstagramLogo}
                                alt="Instagram - Social Media"
                            />
                        </li>
                    </a>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;
